import Alpine from "alpinejs";

/**
 * Parche para poder utilizar x-model con el plugin select2 de jQuery.
 */
Alpine.directive("select2", (el, {}, { evaluateLater, effect }) => {
    // select -> alpine
    $(el).on("select2:select", () => el.dispatchEvent(new Event("change")));
    $(el).on("select2:unselect", () => el.dispatchEvent(new Event("change")));

    // alpine -> select
    const model = el.attributes["x-model"].value;
    let changeSelect = evaluateLater(model);
    effect(() => changeSelect((value) => $(el).val(value).trigger("change")));
});

/**
 * Componente para la creación y edición de actividades.
 * Es el único que se encuentra compilado e independiente del resto de formularios,
 * puesto que este tiene una complejitud bastante superior.
 *
 * En caso que se deban realizar cambio en él, debemos recompilar los assets con el comando:
 *
 * - npm run prod
 */
Alpine.data(
    "activityMaintenanceComponent",
    require("./components/activityMaintenance").default
);

window.Alpine = Alpine;
Alpine.start();
