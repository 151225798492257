import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import esLocale from "@fullcalendar/core/locales/es";
import bootstrapPlugin from "@fullcalendar/bootstrap";

export default {
    plugins: [
        interactionPlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        bootstrapPlugin,
    ],

    locale: esLocale,

    timeZone: "America/Costa_Rica",

    /**
     * Renders the calendar with a given theme system.
     */
    themeSystem: "bootstrap",

    /**
     * Whether to include Saturday/Sunday columns in any of the calendar views.
     */
    weekends: true,

    /**
     * The day that each week begins. Sunday=0, Monday=1, Tuesday=2, etc.
     */
    firstDay: 0,

    /**
     * The initial view when the calendar loads.
     */
    initialView: "dayGridMonth",

    /**
     * Triggered when the user clicks an event.
     */
    // eventClick: this.handleEventClick,

    /**
     * Triggered when resizing stops and the event has changed in duration.
     */
    // eventResize: this.handleEventResize,

    /**
     * Triggered when dragging stops and the event has moved to a different day/time.
     */
    // eventDrop: this.handleEventDrop,

    /**
     * Triggered when the user clicks on a date or a time.
     */
    // dateClick: this.handleDateClick,

    /**
     * Determines the first time slot that will be displayed for each day.
     */
    slotMinTime: "08:00",

    /**
     * Determines the last time slot that will be displayed for each day.
     */
    slotMaxTime: "17:00",

    /**
     * The frequency for displaying time slots.
     */
    slotDuration: "00:30",

    /**
     * Sets the height of the view area of the calendar.
     */
    contentHeight: 560,

    /**
     * Whether or not to display a marker indicating the current time.
     */
    nowIndicator: true,

    /**
     * In, dayGrid view, the max number of events within a given day, not counting the +more link.
     */
    dayMaxEvents: 3,

    /**
     * A JavaScript object that FullCalendar uses to store information about a calendar event.
     */
    events: [],

    /**
     * Defines the buttons and title at the top of the calendar.
     */
    headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
    },

    /**
     * Determines whether the events on the calendar can be modified.
     */
    editable: false,
};
