/**
 * jQuery
 */
window.$ = window.jQuery = require("jquery");

/**
 * jQuery UI
 */
require("jquery-ui");

// Resuelve conflicto entre el tooltip de jQuery UI y Bootstrap Tooltip.
$.widget.bridge("uibutton", $.ui.button);

/**
 * Popper JS
 */
window.Popper = require("popper.js").default;

/**
 * Bootstrap
 */
require("bootstrap");

/**
 * Admin LTE
 */
require("admin-lte");

/**
 * Select 2
 */
require("select2");

/**
 * Overlay Scrollbars
 */
require("overlayscrollbars/js/jquery.overlayScrollbars");

/**
 * Sweet Alert 2
 */
window.Swal = require("sweetalert2");
window.Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
});

/**
 * Axios
 */
window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["X-CSRF-TOKEN"] = document
    .querySelector('meta[name="X-CSRF-TOKEN"]')
    .getAttribute("content");

/**
 * Datatables
 */
require("datatables.net");
require("datatables.net-bs4");
window.DatatableOptions = require("./config/_datatable.config").default;

/**
 * Alpine JS
 */
require("./alpine/alpine");

/**
 * bs-custom-file-input
 */
import bsCustomFileInput from "bs-custom-file-input";
bsCustomFileInput.init();

/**
 * Full Calendar
 */
window.FullCalendar = require("@fullcalendar/core").Calendar;
window.FullCalendarOptions = require("./config/_fullcalendar.config").default;
