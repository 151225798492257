require("./_bootstrap");

/**
 * jQuery Plugins
 */
$(function () {
    $(".select2").select2(
        /* Select2 Configurations */ require("./config/_select2.config").default
    );

    $('[data-toggle="tooltip"]').tooltip();

    /* Sortable Configurations */
    $(".connectedSortable").sortable(
        require("./config/_sortable.config").default
    );
    $(".connectedSortable .card-header").css("cursor", "move");
});
