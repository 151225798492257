import axios from "axios";

export default ({
    activityId,
    category,
    activityToReplaceId,
    date,
    userType,
}) => ({
    isEditing: activityId ? true : false,

    userType: userType,

    errors: null,

    message: null,

    options: {
        activitiesToReplace: [],
        clients: [],
        projects: [],
        activityTypes: [],
        tickets: [],
        authorizationUsers: [],
    },

    get descriptionLength() {
        const description = this.inputs.description;

        return description
            .trim()
            .replaceAll(" ", "")
            .replaceAll("\r", "")
            .replaceAll("\n", "")
            .replaceAll("\t", "").length;
    },

    inputs: {
        category: category ?? "is_normal",
        activityToReplace: "",
        client: "",
        project: "",
        activityType: "",
        description: "",
        ticket: "",
        date: date,
        startTime: {
            hour: "",
            minute: "",
            get full() {
                return `${this.hour}:${this.minute}`;
            },
            set full(time) {
                const [hour, minute] = time.split(":");
                this.hour = hour;
                this.minute = minute;
            },
        },
        endTime: {
            hour: "",
            minute: "",
            get full() {
                return `${this.hour}:${this.minute}`;
            },
            set full(time) {
                const [hour, minute] = time.split(":");
                this.hour = hour;
                this.minute = minute;
            },
        },
        user: "",
    },

    info: {
        project: {
            contractedHours: 0,
            consumedHours: 0,
            get leftHours() {
                const leftHours =
                    parseFloat(this.contractedHours) -
                    parseFloat(this.consumedHours);

                return leftHours < 0 ? 0 : leftHours;
            },
        },
        ticket: {
            estimatedHours: 0,
            consumedHours: 0,
            get leftHours() {
                return (
                    parseFloat(this.estimatedHours) -
                    parseFloat(this.consumedHours)
                );
            },
            isExcluded: false,
        },
        activityToReplace: {},
    },

    get timeDiff() {
        const { hour: sth, minute: stm } = this.inputs.startTime;
        const { hour: eth, minute: etm } = this.inputs.endTime;
        const startTime = new Date(0, 0, 0, sth, stm);
        const endTime = new Date(0, 0, 0, eth, etm);
        return (endTime.getTime() - startTime.getTime()) / 1000 / 3600;
    },

    get extraHours() {
        if (this.info.ticket.isExcluded) return 0;

        return this.info.project.contractedHours > 0 && this.timeDiff > 0
            ? parseFloat(this.timeDiff) -
                  parseFloat(this.info.project.leftHours)
            : 0;
    },

    show: {
        loading: {
            form: false,
            ticketInfo: false,
            projectInfo: false,
        },
    },

    async init() {
        this.loading(async () => {
            this.setWatchers();

            // Utilizamos nextTick para que primero se carguen las opciones
            // de los selects de hora de inicio y fin y después cargue las
            // opciones por defecto.
            this.$nextTick(() => {
                const date = new Date();
                let hours = date.getHours();
                hours = hours < 10 ? `0${hours}` : hours;
                let minutes = date.getMinutes();
                minutes = minutes < 10 ? `0${minutes}` : minutes;

                this.inputs.startTime.full = `${hours}:${minutes}`;
                this.inputs.endTime.full = `${hours}:${minutes}`;
            });

            // Cargamos los clientes.
            await this.fetchClients();

            // Cargamos los usuarios autorizadores.
            await this.fetchAuthorizationUsers();

            // Cargamos las activdades de reposición.
            await this.fetchActivitiesToReplace();

            if (this.isEditing) await this.fetchActivity();
        });
    },

    timeOptions(max) {
        const options = [];
        for (let i = 0; i < max; i++) {
            options.push(i < 10 ? `0${i}` : i);
        }
        return options;
    },

    setWatchers() {
        this.$watch("inputs.category", () => {
            this.inputs.description = "";

            // Volvemos el input date a su estado original.
            this.inputs.date = date;
        });

        this.$watch("inputs.client", async (value, oldValue) => {
            if (oldValue) {
                this.inputs.project = "";
                this.options.projects = [];

                this.inputs.ticket = "";
                this.options.tickets = [];
            }

            this.loading(async () => {
                await this.fetchProjects();
                await this.fetchTickets();
            });
        });

        this.$watch("inputs.project", async (value, oldValue) => {
            if (oldValue) {
                this.inputs.activityType = "";
                this.options.activityTypes = [];
            }

            if (!value) return;

            this.loading(async () => await this.fetchActivityTypes());

            this.loading(async () => {
                await this.fetchProjectConsumedHours();
                await this.fetchProjectContractedHours();
            }, "projectInfo");
        });

        // Cuando cambiamos el tiquete.
        this.$watch("inputs.ticket", async (value) => {
            if (!value || value == 0) return;

            this.loading(async () => {
                await this.fetchTicketConsumedHours();
                await this.fetchTicketEstimatedHours();
                await this.fetchIfTicketIsExcluded();
            }, "ticketInfo");
        });
    },

    async loading(callback, form = "form") {
        this.show.loading[form] = true;
        await callback();
        this.show.loading[form] = false;
    },

    async submit() {
        this.loading(async () => {
            this.errors = null;
            this.message = null;

            const formData = {
                category: this.inputs.category,
                activity_to_replace: this.inputs.activityToReplace,
                client_id: this.inputs.client,
                project_id: this.inputs.project,
                activity_type_id: this.inputs.activityType,
                description: this.inputs.description,
                ticket_id: this.inputs.ticket,
                date: this.inputs.date,
                start_time: this.inputs.startTime.full,
                end_time: this.inputs.endTime.full,
                time_diff: this.timeDiff,
                extra_hours: this.extraHours,
                user_id: this.inputs.user,
            };

            const { data } = this.isEditing
                ? await axios.put(`/activities/${activityId}`, formData)
                : await axios.post("/activities/store", formData);

            const { status, message, route, errors } = data;

            if (status) return window.location.replace(route);
            if (errors) this.errors = errors;
            if (message) this.message = message;

            $("html, body").animate({ scrollTop: 0 }, "slow");
        });
    },

    async fetchActivity() {
        const { data: activity } = await axios.get(
            `/activities/maintenance/${activityId}`
        );

        this.inputs.activityToReplace = activityToReplaceId ?? "";

        this.inputs.client = activity.id_client;
        this.inputs.project = activity.id_proyect;
        this.inputs.activityType = activity.id_activity_type;
        this.inputs.description = activity.activity_description;
        this.inputs.startTime.full = activity.activity_beginhour;
        this.inputs.endTime.full = activity.activity_endhour;
        this.inputs.ticket =
            activity.ticket_id && activity.ticket_id != 0
                ? activity.ticket_id
                : "";

        // Cambiamos el formato de la fecha para que pueda ser procesado
        // por el input de tipo date.
        const [day, month, year] = activity.activity_date.split("-");
        this.inputs.date = `${year}-${month}-${day}`;

        if (this.inputs.category == "is_replacement") {
            this.info.activityToReplace = await axios
                .get(`/activities/maintenance/${activityToReplaceId}`)
                .then(({ data }) => data);
        }
    },

    async fetchClients() {
        this.options.clients = await axios
            .get("/clients")
            .then(({ data }) => data);

        /**
         * Debemos seleccionar nuevamente el valor del <select> ya que cuando Alpine vuelve a renderizar
         * las opciones, el <select> toma por defecto el valor como null generando un error.
         * Además, debemos utilizar la función mágica $nextTick ya que debemos esperar que se renderizen
         * las opciones para poder darle un valor.
         */
        this.$nextTick(() =>
            $("#js-client-id").val(this.inputs.client).trigger("change")
        );
    },

    async fetchAuthorizationUsers() {
        this.options.authorizationUsers = await axios
            .get("/users/authorization-users")
            .then(({ data }) => data);
    },

    async fetchActivitiesToReplace() {
        this.options.activitiesToReplace = await axios
            .get("/activities/to-replace")
            .then(({ data }) => data);
    },

    async fetchProjects() {
        this.options.projects = await axios
            .get(`/projects/by-client/${this.inputs.client}`)
            .then(({ data }) => data);

        /**
         * Debemos seleccionar nuevamente el valor del <select> ya que cuando Alpine vuelve a renderizar
         * las opciones, el <select> toma por defecto el valor como null generando un error.
         * Además, debemos utilizar la función mágica $nextTick ya que debemos esperar que se renderizen
         * las opciones para poder darle un valor.
         */
        this.$nextTick(() =>
            $("#js-project-id").val(this.inputs.project).trigger("change")
        );
    },

    async fetchTickets() {
        this.options.tickets = await axios
            .get(`/tickets/by-client/${this.inputs.client}`)
            .then(({ data }) => data);

        /**
         * Debemos seleccionar nuevamente el valor del <select> ya que cuando Alpine vuelve a renderizar
         * las opciones, el <select> toma por defecto el valor como null generando un error.
         * Además, debemos utilizar la función mágica $nextTick ya que debemos esperar que se renderizen
         * las opciones para poder darle un valor.
         */
        this.$nextTick(() =>
            $("#js-ticket-id").val(this.inputs.ticket).trigger("change")
        );
    },

    async fetchActivityTypes() {
        this.options.activityTypes = await axios
            .get(`/activity-types/by-project/${this.inputs.project}`)
            .then(({ data }) => data);

        /**
         * Debemos seleccionar nuevamente el valor del <select> ya que cuando Alpine vuelve a renderizar
         * las opciones, el <select> toma por defecto el valor como null generando un error.
         * Además, debemos utilizar la función mágica $nextTick ya que debemos esperar que se renderizen
         * las opciones para poder darle un valor.
         */
        this.$nextTick(() =>
            $("#js-activity-type-id")
                .val(this.inputs.activityType)
                .trigger("change")
        );
    },

    async fetchProjectConsumedHours() {
        const { project, date } = this.inputs;
        this.info.project.consumedHours = await axios
            .get(`/projects/consumed-hours/${project}/${date}/`)
            .then(({ data }) => data);
    },

    async fetchProjectContractedHours() {
        this.info.project.contractedHours = await axios
            .get(`/projects/contracted-hours/${this.inputs.project}`)
            .then(({ data }) => data);
    },

    async fetchTicketEstimatedHours() {
        this.info.ticket.estimatedHours = await axios
            .get(`/tickets/estimated-hours/${this.inputs.ticket}`)
            .then(({ data }) => data);
    },

    async fetchTicketConsumedHours() {
        this.info.ticket.consumedHours = await axios
            .get(`/tickets/consumed-hours/${this.inputs.ticket}`)
            .then(({ data }) => data);
    },

    async fetchIfTicketIsExcluded() {
        this.info.ticket.isExcluded = await axios
            .get(`/tickets/is-excluded/${this.inputs.ticket}`)
            .then(({ data }) => data);
    },
});
